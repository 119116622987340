<template>
  <v-container style="height:calc(100% - 70px);min-width:1024px;min-height:768px;max-width:100%;width:95%;">
    <v-row>
      <v-col cols="12" class="py-2" style="padding-left:0px;">
        <v-form>
          <div class="d-flex align-center">
            <div class="mr-auto" v-if="this.$store.state.groupName != 'admin'">
              <h2 color="primary" large label outlined><v-icon large color="primary">mdi-map-marker-radius</v-icon>{{ this.$store.state.groupName }}</h2>
            </div>
            <div class="mr-auto" v-if="this.$store.state.groupName == 'admin'">
              <v-autocomplete v-model="selectedGroup" :items="groups" label="위치 선택" 
                item-text="groupName" item-value="groupId" return-object></v-autocomplete>
            </div>
            <div class="mx-3" style="width:260px;">
              <vc-date-picker v-model="fromTime" mode="dateTime" :max-date='new Date()' is-required is24hr>
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="fromTextField" label="From" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" 
                    :error-messages="fromRangeErr" :rules="[checkFromRange]" readonly></v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div><v-icon small>mdi-tilde</v-icon></div>
            <div class="mx-3" style="width:260px;">
              <vc-date-picker v-model="toTime" mode="dateTime" :max-date='new Date()' is-required is24hr>
                <template v-slot="{ inputValue, inputEvents }">
                  <v-text-field ref="toTextField" label="To" prepend-icon="mdi-calendar-month" 
                    :value="inputValue" v-on="inputEvents" 
                    :error-messages="toRangeErr"  :rules="[checkToRange]" readonly></v-text-field>
                </template>
              </vc-date-picker>
            </div>
            <div class="pb-2 ml-4" style="width:150px;">
              <v-btn block color="primary" elevation="0" @click="doSearch" :disabled="fromRangeErr != '' || toRangeErr != '' || selectedGroup == null">
                <v-icon dark>mdi-magnify</v-icon>검색
              </v-btn>
            </div>
            <div class="pb-2 ml-4 mr-0" style="width:150px;">
              <CSVExportDialog :selectedGroup="selectedGroup" v-on:csvDownload="csvDownload" v-on:clear="clearExportDlg"
                :loading="csvExportLoading" :resultMessage="csvExportErr != '' ? csvExportErr : csvExportSuccess" 
                :resultType="csvExportErr != '' ? 'error' : 'success'" :disabled="selectedGroup == null" 
                :download-cnt="imgDownloadCnt" :total-cnt="imgTotalCnt"
                :step-desc="stepDesc" />
            </div>
          </div>
        </v-form>
      </v-col>
      <v-col cols="12" class="pa-2">
        <v-row>
          <v-col class="overflow-y-auto overflow-x-auto" cols="9" style="height:calc(100vh - 200px);">
            <DataTable :tableData="tableData" ></DataTable>   
          </v-col>
          <v-col class="overflow-y-auto" cols="3" style="height:calc(100vh - 200px);">
            <EdgeEyePanel :edgeEyeData="edgeEyeData" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay v-if="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import axios from 'axios';
import exportFromJSON from 'export-from-json';
import CSVExportDialog from './SearchDataTable/CSVExportDialog.vue';
import DataTable from './SearchDataTable/DataTable.vue';
import EdgeEyePanel from './SearchDataTable/EdgeEyePanel.vue';
import Utils from '../js/utils';
import moment from 'moment';
import Jszip from 'jszip';
import JszipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';

let removeKeys = ['rssi', 'snr', 'fPort', 'fCnt', 'freq', 'mod', 'bw', 'adr', 'sf', 'cr', 'gateway', 'raw'];

Date.prototype.YYYYMMDDhhmmss = function() {
  var MM = this.getMonth() + 1; // getMonth() is zero-based
  var DD = this.getDate();
  var hh = this.getHours();
  var mm = this.getMinutes();
  var ss = this.getSeconds();

  return [this.getFullYear(),
          (MM>9 ? '' : '0') + MM,
          (DD>9 ? '' : '0') + DD, '-',
          (hh>9 ? '' : '0') + hh,
          (mm>9 ? '' : '0') + mm,
          (ss>9 ? '' : '0') + ss,
         ].join('');
};

Date.prototype.YYYYMMDDhhmm = function() {
  var MM = this.getMonth() + 1; // getMonth() is zero-based
  var DD = this.getDate();
  var hh = this.getHours();
  var mm = this.getMinutes();

  return [this.getFullYear(),
          (MM>9 ? '' : '0') + MM,
          (DD>9 ? '' : '0') + DD, '-',
          (hh>9 ? '' : '0') + hh,
          (mm>9 ? '' : '0') + mm,
         ].join('');
};

export default {
  name: 'SearchDataTable',
  components: {
    CSVExportDialog,
    DataTable,
    EdgeEyePanel
  },
  mounted() {
    this.loadGroupList();
  },
  methods: {
    forceRerender() {
      this.redrawKey += 1;
    },
    async loadGroupList() {
      if(this.$store.state.groupName == 'admin') {
        try {
          let ret = await axios({ method: 'get', url: '/api/groups',
            headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
          });
          let groups = ret.data.groups;
          for(let i=0; i<groups.length; i++) {
            this.groups.push({groupName: groups[i].groupName, groupId: groups[i].groupId});
            if(i==0) {
              this.selectedGroup = {groupName: groups[i].groupName, groupId: groups[i].groupId};
            }
          }
        } catch(e) {
          console.log(e);
        }
      }
    },
    checkFromRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.fromRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.toTime);
        limitTime = moment(limitTime).subtract(366, 'day').toDate();
        if(limitTime.getTime() > this.fromTime.getTime()) {
          this.fromRangeErr = "검색 범위는 1년까지 입니다.";
          return false;
        }

        this.fromRangeErr = "";
        if(this.toRangeErr != "") {
          this.$refs.toTextField.validate(true);
        }
      }
      return true;
    },
    checkToRange() {
      if(this.fromTime != null && this.toTime != null) {
        if(this.toTime.getTime() < this.fromTime.getTime()) {
          this.toRangeErr = "시간 조건을 재확인해주세요";
          return false;
        }

        let limitTime = new Date(this.fromTime);
        limitTime = moment(limitTime).add(366, 'day').toDate();
        if(limitTime.getTime() < this.toTime.getTime()) {
          this.toRangeErr = "검색 범위는 1년까지 입니다.";
          return false;
        }

        this.toRangeErr = "";
        if(this.fromRangeErr != "") {
          this.$refs.fromTextField.validate(true);
        }
      }
      return true;
    },
    async getDevices() {
      let grpId = '', stn = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
      }
      let ret = await axios({ method: 'get', url: '/api/devices' + grpId,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      let devices = ret.data.devices;
      let rainDev = null;
      let flowDev = null;
      let solarDev = null;
      let edgeEye = [];
      if(devices != null || devices.length > 0) {
        let sensors = [];
        for(let i=0; i<devices.length; i++) {
          if(devices[i].devType == '강우량계') {
            rainDev = devices[i];
            stn = JSON.parse(`{${devices[i].devDesc}}`).stn;
          } else if(/^유량계-.*/.test(devices[i].devType)) {
            sensors.push({
              devId: devices[i].devId, 
              devType: devices[i].devType,
              devDesc: devices[i].devDesc
            });
            if(stn == null || stn == undefined || stn == '') {
              stn = JSON.parse(`{${devices[i].devDesc}}`).stn;
            }
          } else if(devices[i].devType == 'EdgeEye') {
            edgeEye.push({
              devId: devices[i].devId,
              devDesc: devices[i].devDesc
            });
          } else if(devices[i].devType == 'CSD3') {
            solarDev = devices[i];
          }
        }
        flowDev = Utils.devSort(sensors);
      }
      return {solarDev: solarDev, rainDev: rainDev, flowDev: flowDev, edgeEye: edgeEye, stn: stn};
    },
    async getDevData(nid, to, from) {
      let grpId = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
      }
      let queryStr = `nid=${nid}&from=${from}&to=${to}`;
      let ret = await axios({ method: 'get', url: `/api/data${grpId}?${queryStr}`,
        headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
      });
      return ret.data.data;
    },
    async doSearch() {
      this.loading = true;
      try {
        let from = this.fromTime.toISOString();
        let to = this.toTime.toISOString();
        let devices = await this.getDevices();
        let nids = '';
        let _edgeEyeData = [];
        let _tableData = [];
        
        let grpId = '';
        if(this.$store.state.groupName == 'admin') {
          grpId = `/${this.selectedGroup.groupId}`;
        }

        if(devices.solarDev != null) {
          nids += devices.solarDev.devId;
        }
        if(devices.rainDev != null) {
          if(nids.length > 0) {
            nids += ',';
          }
          nids += devices.rainDev.devId;
        }
        if(devices.flowDev != null) {
          for(let i=0; i<devices.flowDev.length; i++) {
            if(nids.length > 0) {
              nids += ',';
            }
            nids += devices.flowDev[i].devId;
          }
        }
        if(devices.edgeEye.length > 0) {
          for(let i=0; i<devices.edgeEye.length; i++) {
            if(nids.length > 0) {
              nids += ',';
            }
            nids += devices.edgeEye[i].devId;
            _edgeEyeData.push({
              grpId: grpId,
              devId: devices.edgeEye[i].devId,
              devDesc: devices.edgeEye[i].devDesc,
              images: []
            })
          }
        }
        let queryStr = `nid=${nids}&from=${from}&to=${to}`;
        let ret = await axios({ method: 'get', url: `/api/data${grpId}?${queryStr}`,
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json'},
        });

        let data = ret.data.data;
        for(let i=0; i<data.length; i++) {
          let _isEdgeEye = false;
          for(let j=0; j<_edgeEyeData.length; j++) {
            if(_edgeEyeData[j].devId == data[i].node_id) {
              _isEdgeEye = true;
              if(data[i].value.image != undefined && data[i].value.image != null) {
                _edgeEyeData[j].images.push({...data[i].value.image, sense_time: data[i].value.sense_time});
              }
              break;
            }
          }
          if(!_isEdgeEye) {
            _tableData.push(data[i]);
          }
        }
        this.tableData = _tableData;
        this.edgeEyeData = _edgeEyeData;
      } catch(e) {
        console.log(e);
        this.$notify({
            type: 'error',
            group: 'foo',
            text: `검색중 에러가 발생했습니다. 다시 시도해주십시오.`,
            duration: 60 * 1000
          });
      }
      this.loading = false;
    },
    async csvDownload(from, to, withImage) {
      this.csvExportLoading = true;
      this.totalCnt = 0;
      this.downloadCnt = 0;
      this.csvExportErr = '';
      this.csvExportSuccess = '';
      let grpId = '', grpName = '';
      if(this.$store.state.groupName == 'admin') {
        grpId = `/${this.selectedGroup.groupId}`;
        grpName = this.selectedGroup.groupName;
      }

      from = new Date(from);
      to = new Date(to);
      let fromStr = from.toISOString();
      let toStr = to.toISOString();
      this.stepDesc = "센서 데이터 다운로드 진행중...";
      try {
        let devices = await this.getDevices();
        let dataList = [];
        if(devices.solarDev != null || devices.rainDev != null || devices.flowDev.length > 0) {
          if(devices.solarDev != null) {
            let devData = await this.getDevData(devices.solarDev.devId, toStr, fromStr);
            dataList.push({
              fileName: this.getFileName(`${devices.solarDev.devType}`, from, to),
              data: devData
            });
          }
          if(devices.flowDev != null) {
            for(let i=0; i<devices.flowDev.length; i++) {
              let devData = await this.getDevData(devices.flowDev[i].devId, toStr, fromStr);
              dataList.push({
                fileName: this.getFileName(`${devices.flowDev[i].devType}`, from, to),
                data: devData
              });
            }
          }
          if(devices.rainDev != null) {
            let devData = await this.getDevData(devices.rainDev.devId, toStr, fromStr);
            dataList.push({
              fileName: this.getFileName(`${devices.rainDev.devType}`, from, to),
              data: devData
            });
          }
        }
        let exportType = exportFromJSON.types.csv;
        let fileCnt = 0;
        for(let i=0; i<dataList.length; i++) {
          let dataArr = dataList[i].data;
          if(dataArr.length == 0) {
            continue;
          }
          for(let j=0; j<dataArr.length; j++) {
            delete dataArr[j]._id;
            delete dataArr[j].group_id;
            dataArr[j].timestamp = new Date(dataArr[j].timestamp).toLocaleString();
            let val = dataArr[j].value;
            for(let tag in val) {
              if(!removeKeys.includes(tag)) {
                dataArr[j][tag] = val[tag];
              }
            }
            delete dataArr[j].__v;
            delete dataArr[j].value;
          }
          let fileName = dataList[i].fileName;
          exportFromJSON({data: dataArr, fileName: fileName, exportType: exportType, withBOM: true});
          fileCnt++;
        }

        this.stepDesc = "이미지 파일 검색중...";
        let imgDev = [];
        let imgTotalCnt = 0;
        let imgErrCnt = 0;
        if(devices.edgeEye.length > 0 && withImage) {
          for(let i=0; i<devices.edgeEye.length; i++) {
            let devData = await this.getDevData(devices.edgeEye[i].devId, toStr, fromStr);
            let urls = [];
            for(let j=0; j<devData.length; j++) {
              if(devData[j].value.image != undefined && devData[j].value.image != null) {
                if(j == 0 || devData[j].value.sense_time != devData[j-1].value.sense_time) { //skip duplicated sense time
                  urls.push({
                    url: `/api/data/image${grpId}/${devData[j].value.image.file_id}`,
                    timestamp: new Date(devData[j].value.sense_time).YYYYMMDDhhmmss()
                  });
                  imgTotalCnt++;
                }
              }
            }
            imgDev.push({devId: devices.edgeEye[i].devId, urls: urls});
          }
          this.stepDesc = "이미지 파일 다운로드 진행중...";
          this.imgTotalCnt = imgTotalCnt;
          console.log(`img count: ${imgTotalCnt}`);
          if(imgTotalCnt > 0) {
            this.imgDownloading = true;
            let zipFileName = `EdgeEye-${grpName}.zip`;
            let zip = new Jszip();
            for(let i=0; i<imgDev.length; i++) {
              for(let j=0; j<imgDev[i].urls.length; j++) {
                try {
                  imgTotalCnt--;
                  let imgData = await Utils.readImageFromURL(imgDev[i].urls[j].url);
                  zip.file(`${imgDev[i].devId}/${imgDev[i].urls[j].timestamp}.jpg`, imgData, {binary:true});
                  this.imgDownloadCnt = this.imgDownloadCnt + 1;
                  if (imgTotalCnt == 0) {
                    zip.generateAsync({type:'blob'}).then((content) => {
                      saveAs(content, zipFileName);
                      this.imgDownloading = false;
                      this.csvExportLoading = false;
                      this.csvExportSuccess = `총 ${fileCnt}개의 CSV파일과 이미지 압축파일이 다운로드 완료됐습니다.`;
                    });
                  }
                } catch(e) {
                  imgErrCnt++;
                  console.log(e);
                }
              }
            }
          } 
        }
        if(!this.imgDownloading) {
          this.csvExportSuccess = `총 ${fileCnt}개의 CSV파일이 다운로드 완료됐습니다.`;
        }
        if(this.imgErrCnt > 0) {
          this.csvExportErr = `총 ${this.imgErrCnt}개의 이미지는 다운로드가 실패하여 제외됐습니다.`
        }
        if(dataList.length == 0) {
          this.csvExportErr = "데이터가 존재하지 않습니다.";
        } else {
          this.csvExportErr = '';
        }
        this.stepDesc = '';
      } catch(e) {
        console.log(e);
        this.csvExportErr = e.toString();
      }
      this.csvExportLoading = this.imgDownloading ? true : false;
    },
    getFileName(devType, from, to) {
      let grpName = this.$store.state.groupName == 'admin' ? this.selectedGroup.groupName : this.$store.state.groupName;
      return `${from.YYYYMMDDhhmm()}-${to.YYYYMMDDhhmm()}-${devType}-${grpName}`;
    },
    clearExportDlg() {
      this.csvExportErr = '';
      this.csvExportSuccess = '';
      this.withImage = false;
      this.imgTotalCnt = 0;
      this.imgDownloadCnt = 0;
    }
  },
  data() {
    let toTime = new Date();
    toTime.setHours(23, 59, 59);
    let fromTime = new Date();
    fromTime.setDate(toTime.getDate() - 7);
    fromTime.setHours(0);
    fromTime.setMinutes(0);
    fromTime.setSeconds(0);
    return {
      emtpyHint: "위치를 선택하고 검색해주세요",
      loading: false,
      redrawKey: 0,
      fromRangeErr: "", toRangeErr: "",
      fromTime: fromTime, toTime: toTime,
      colorIdx: 0,
      datasets: [],
      chartList: [],
      selectedGroup: {},
      groups: [],
      chartStyle: {
        titleColor: 'rgba(21,101,192, 1.0)',
      },
      csvExportLoading: false,
      imgDownloading: false,
      csvExportErr: '',
      csvExportSuccess: '',
      tableHeaders: [
        { text: '게이트웨이 ID', value: 'gateway_id', width: '20%', align: 'center', divider: true},
        { text: '장치 ID', value: 'node_id', width: '20%', align: 'center', divider: true},
        { text: '타임스탬프', value: 'timestamp', width: '20%', align: 'center', divider: true},
        { text: '데이터', value: 'value', width: '40%', align: 'center', divider: true},
      ],
      tableData: [],
      edgeEyeData: [],
      tableSearch: '',
      imgTotalCnt: 0,
      imgDownloadCnt: 0,
      stepDesc: ''
    }
  }
}
</script>